import React, { useState, useEffect } from "react";
import axios from "axios";
import UserNotFound from "../../Assets/UserNotFound.svg";
import API_DOMAIN from "../../ApiConfig";
import Cookies from "js-cookie";
import "./DirectNotice.css";
import { jwtDecode } from "jwt-decode";

export default function NotFound() {
  const [username, setUsername] = useState("");
  const userId = Cookies.get("userId");

  useEffect(() => {
    const img = new Image();
    img.src = UserNotFound;
  }, []);

  useEffect(() => {
    const fetchUsername = async () => {
      const response = await axios.get(`${API_DOMAIN}/user/view`, {
        params: { userId },
      });
      const result = response.data;
      const { userName } = jwtDecode(result.token);
      setUsername(userName);
    };
    fetchUsername();
  }, [userId]);

  const handleGoBack = () => {
    window.location.href = `/@${username}`;
  };

  return (
    <div className="direct-notice-container">
      <div className="direct-notice">
        <div className="d-notice-image">
          <img src={UserNotFound} alt="direct-notice" />
        </div>
        <div className="d-notice-title">Oops! Profile Not Found</div>
        <div className="d-notice-desc">
          It looks like the profile you’re trying to view doesn’t exist. It
          might have been deleted or the URL may be incorrect.
        </div>
        <div className="d-notice-btns">
          <button className="notice-stay" onClick={handleGoBack}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}
