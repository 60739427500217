import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { AiFillHeart } from "react-icons/ai";
import Select from "react-select";
import StepBar from "../../Components/StepBar/StepBar";
import "./SetDemo.css";

export default function SetDemo() {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [desError, setDesError] = useState("");
  const [city, setCity] = useState("");
  const [position, setPosition] = useState("");
  const [workplace, setWorkplace] = useState("");
  const [workError, setWorkError] = useState("");

  const fetchCountries = async () => {
    const country = await Axios.get(
      "https://countriesnow.space/api/v0.1/countries"
    );
    setCountries(country.data.data);
  };

  const fetchCities = (country) => {
    const city = countries.find((c) => c.country === country);
    setCities(city.cities);
  };

  useEffect(() => {
    const savedCountry = sessionStorage.getItem("country");
    const savedCity = sessionStorage.getItem("city");
    const savedDesignation = sessionStorage.getItem("position");
    const savedWorkPlace = sessionStorage.getItem("workplace");

    if (savedCountry) setCountry(savedCountry);
    if (savedCity) setCity(savedCity);
    if (savedDesignation) setPosition(savedDesignation);
    if (savedWorkPlace) setWorkplace(savedWorkPlace);

    window.addEventListener("beforeunload", clearSessionStorage);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, []);

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleClick = () => {
    if (country) {
      sessionStorage.setItem("country", country);
    } else {
      sessionStorage.removeItem("country");
    }

    if (city) {
      sessionStorage.setItem("city", city);
    } else {
      sessionStorage.removeItem("city");
    }

    if (position) {
      sessionStorage.setItem("position", position);
    } else {
      sessionStorage.removeItem("position");
    }

    if (workplace) {
      sessionStorage.setItem("workplace", workplace);
    } else {
      sessionStorage.removeItem("workplace");
    }
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("country");
    sessionStorage.removeItem("city");
    sessionStorage.removeItem("position");
    sessionStorage.removeItem("workplace");
  };

  const validateDesignation = (e) => {
    const enteredDesignation = e.target.value;
    setPosition(enteredDesignation);
    const designation = enteredDesignation.trim();
    const designationRegex = /^[A-Za-z\s]{1,30}$/;

    if (designationRegex.test(designation)) {
      setDesError("");
    } else {
      setDesError("Enter a valid Designation");
    }
    if (!enteredDesignation) {
      setDesError("");
    }
  };

  const validateWorkplace = (e) => {
    const enteredWorkplace = e.target.value;
    setWorkplace(enteredWorkplace);
    const workplace = enteredWorkplace.trim();
    const workplaceRegex = /^[A-Za-z0-9\s\-&()'$]+$/;

    if (workplaceRegex.test(workplace)) {
      setWorkError("");
    } else {
      setWorkError("Enter a valid workplace");
    }
    if (!enteredWorkplace) {
      setWorkError("");
    }
  };

  return (
    <div className="demo-container">
      <div className="demo-wrapper">
        <div className="demo-header">
          <StepBar num={3} />
        </div>
        <div className="demo-body">
          <div className="db-wrapper">
            <div className="db-topic">
              Let's craft your Tinly experience together.
            </div>
            <div className="db-sub-topic">
              <h>Add Your Demographic Details Here</h>
            </div>
            <div className="add-demo">
              <div className="location-sec">
                <span className="label">Your Country</span>
                <Select
                  className="demo-location"
                  options={countries.map((country) => ({
                    value: country.country,
                    label: country.country,
                  }))}
                  onChange={(selectedOption) => {
                    setCountry(selectedOption.value);
                    fetchCities(selectedOption.value);
                  }}
                  placeholder="Select Country"
                />
              </div>
              <div className="location-sec">
                <span className="label">Your City</span>
                <Select
                  className="demo-location"
                  options={cities.map((city) => ({ value: city, label: city }))}
                  onChange={(selectedOption) => setCity(selectedOption.value)}
                  placeholder="Select City"
                />
              </div>
              <div className="designation-sec">
                <span className="label">Your Designation</span>
                <input
                  type="text"
                  className="demo-text"
                  placeholder=""
                  maxLength={50}
                  value={position}
                  onChange={validateDesignation}
                />
                {desError && (
                  <div className="demo-error-message">
                    <p>{desError}</p>
                  </div>
                )}
              </div>

              <div className="workplace-sec">
                <span className="label">Your Work Place</span>
                <input
                  type="text"
                  className="demo-text"
                  placeholder=""
                  maxLength={50}
                  value={workplace}
                  onChange={validateWorkplace}
                />
                {workError && (
                  <div className="demo-error-message">
                    <p>{workError}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="db-button">
              <Link to="/bio">
                <button className="btn-prev" onClick={handleClick}>
                  {" "}
                  &lt; Previous{" "}
                </button>
              </Link>
              <Link to="/theme">
                <button onClick={handleClick}> Next &gt; </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="db-footer">
          <p>
            Made &nbsp;
            <AiFillHeart className="heart-icon" /> &nbsp; with Tinly
          </p>
        </div>
      </div>
    </div>
  );
}
