import React from "react";
import { FaInfo } from "react-icons/fa6";
import "./InputInfo.css";

export default function InputInfo({ Description }) {
  return (
    <div className="info-sec">
      <div className="info-icon">
        <FaInfo />
      </div>
      <div className="info-desc">{Description}</div>
    </div>
  );
}
