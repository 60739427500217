import React, { useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

export default function UserConfirm({
  guestUserID,
  fetchCircles,
  fetchNotificationCount,
  isFromGuest,
  setIsFromGuest,
}) {
  const [circleChecked, setCircleChecked] = useState("");
  const [isCirclesCreated, setIsCirclesCreated] = useState(false);
  const [availableCircles, setAvailableCircles] = useState([]);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [isAcceptReq, setIsAcceptReq] = useState({});
  const [isProcessing, setIsProcessing] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState();

  const userId = Cookies.get("userId");

  const handleCircleChange = (option) => {
    setCircleChecked(option);
  };

  const handleConnect = () => {
    fetchCircleData();
    setIsPopupOpen(true);
  };

  const fetchCircleData = async () => {
    try {
      const response = await axios.get(`${API_DOMAIN}/circle/view`, {
        params: { userId },
      });
      const result = response.data;
      if (result.success) {
        const { circles } = jwtDecode(result.token);
        const fetchedCircles = circles
          .map((link) => {
            const color = link.color;
            if (color === "N/A") {
              return null;
            } else {
              setIsCirclesCreated(true);
              return {
                type: link.name,
              };
            }
          })
          .filter(Boolean);
        setAvailableCircles(fetchedCircles);
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const updateState = async (state) => {
    try {
      const requestBody = {
        userId: userId,
        followerId: guestUserID,
        state: state,
      };
      await axios.put(`${API_DOMAIN}/follower/update`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (state === "delete") {
        fetchNotificationCount();
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const insertFollower = async () => {
    setIsProcessing((prev) => ({ ...prev, [guestUserID]: true }));
    try {
      const response = await axios.post(
        `${API_DOMAIN}/user/circle/save/${userId}`,
        { userId, targetUserId: guestUserID, circleName: circleChecked },
        { headers: { "Content-Type": "application/json" } }
      );

      const result = response.data;
      if (result.success) {
        setIsAcceptReq((prev) => ({ ...prev, [guestUserID]: true }));
        await updateState("confirm");

        setIsPopupOpen(false);

        if (isFromGuest) {
          setIsFromGuest(false);
        } else {
          fetchCircles();
        }
        setTimeout(() => {
          fetchNotificationCount();
        }, 5000);
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsProcessing((prev) => ({ ...prev, [guestUserID]: false }));
    }
  };

  return (
    <>
      {isAcceptReq[guestUserID] ? (
        <div className="added-user">
          You added this user to {circleChecked} circle
        </div>
      ) : (
        !isProcessing[guestUserID] && (
          <div className="notification-btns">
            <button
              className="notification-confirm"
              onClick={() => {
                handleConnect();
              }}
            >
              Confirm
            </button>
            <button
              className="notification-delete"
              onClick={() => {
                updateState("delete");
              }}
            >
              Delete
            </button>
          </div>
        )
      )}
      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
        nested
      >
        {(close) => (
          <div className="add-circle-popup">
            <div className="acp-header">
              <div className="acp-title">Select a circle</div>
              <button className="acp-close" onClick={() => close()}>
                <RxCross2 />
              </button>
            </div>
            <div className="acp-body">
              {isCirclesCreated ? (
                availableCircles.map((link, index) => (
                  <div className="created-circle-name" key={index}>
                    <input
                      type="radio"
                      checked={circleChecked === link.type}
                      onChange={() => handleCircleChange(link.type)}
                      className="acp-radio-btn"
                    />
                    <div className="acp-radio-label">{link.type}</div>
                  </div>
                ))
              ) : (
                <div className="loader-class">
                  <div className="loader"></div>
                </div>
              )}
            </div>

            <div className="acp-footer">
              {isCirclesCreated && circleChecked !== "customize" && (
                <button
                  className="acp-select-color-btn"
                  onClick={() => {
                    insertFollower();
                    setIsPopupOpen(false);
                    updateState("confirm");
                  }}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}
