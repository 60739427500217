import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

function MoveUser({
  isPopup,
  moveUserIds,
  closeManageCircleModal,
  fetchCircles,
  nameOfCircle,
  isVisible,
  setIsVisible,
  setIsUsersMoved,
  moveCircleName,
  setIsSaveChanges,
  setIsAddCircle,
  setIsUsersDeleted,
  setIsCircleDeleted,
}) {
  const [circleChecked, setCircleChecked] = useState("");
  const [isCirclesCreated, setIsCirclesCreated] = useState(false);
  const [availableCircles, setAvailableCircles] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [action, setAction] = useState("");
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCircleChange = (option) => {
    setCircleChecked(option);
    moveCircleName(option);
  };

  const userId = Cookies.get("userId");

  useEffect(() => {
    const fetchCircleData = async () => {
      try {
        const response = await axios.get(`${API_DOMAIN}/circle/view`, {
          params: { userId },
        });
        const result = response.data;
        if (result.success) {
          const { circles } = jwtDecode(result.token);
          const fetchedCircles = circles
            .map((link) => {
              const color = link.color;
              if (color === "N/A") {
                return null;
              } else {
                setIsCirclesCreated(true);
                return {
                  type: link.name,
                };
              }
            })
            .filter(Boolean);
          setAvailableCircles(
            fetchedCircles.filter((circle) => circle.type !== nameOfCircle)
          );
        }
      } catch (error) {
        if (error.message === "Failed to fetch") {
          setIsConsoleError(true);
          setConsoleError("Unable to connect. Please check back shortly!");
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    };
    fetchCircleData();
  }, [userId, nameOfCircle]);

  const closeModalAfterDelay = () => {
    return new Promise(() => {
      closeManageCircleModal(false);
      isPopup(false);
      setIsUsersMoved(true);
      setIsSaveChanges(false);
      setIsAddCircle(false);
      setIsUsersDeleted(false);
      setIsCircleDeleted(false);
      setIsVisible(true);
    });
  };

  const moveUser = async (circleType) => {
    setIsLoading(true);
    try {
      const users = moveUserIds;
      if (!users.length) {
        setIsUserSelected(true);
        setAction("Move");
      } else {
        const response = await axios.put(
          `${API_DOMAIN}/user/circle/update/${userId}`,
          {
            users: users,
            newCircleName: circleType,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = response.data;
        if (result.success) {
          closeModalAfterDelay();
          fetchCircles();
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="add-circle-popup">
        <div className="acp-header">
          <div className="acp-title">Select a circle</div>
          <div className="acp-close" onClick={() => isPopup(false)}>
            <RxCross2 />
          </div>
        </div>
        <div className="acp-body">
          {isCirclesCreated ? (
            availableCircles.map((link, index) => (
              <div className="acp-body-radio" key={index}>
                <input
                  type="radio"
                  checked={circleChecked === link.type}
                  onChange={() => handleCircleChange(link.type)}
                  className="acp-radio-btn"
                />
                <div className="acp-radio-label">{link.type}</div>
              </div>
            ))
          ) : (
            <div className="loader-class">
              <div className="loader"></div>
            </div>
          )}
        </div>

        <div className="acp-footer">
          {isCirclesCreated && circleChecked !== "customize" && (
            <div
              className="acp-select-color-btn"
              onClick={() => moveUser(circleChecked)}
            >
              {isLoading ? <div className="spinner"></div> : "Move"}
            </div>
          )}
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
        closeNoSelectError={setIsUserSelected}
        isUserSelected={isUserSelected}
        action={action}
      />
    </>
  );
}

export default MoveUser;
