import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import t1 from "../../Assets/ThemeRed.svg";
import t2 from "../../Assets/ThemeGreen.svg";
import t3 from "../../Assets/ThemeBlue.svg";
import StepBar from "../../Components/StepBar/StepBar";
import Footer from "../../Components/Footer/Footer";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../../Components/ConfirmationMessage/ConsoleError";
import "./SetTheme.css";
import axios from "axios";

const SetTheme = () => {
  const [selectedTheme, setSelectedTheme] = useState({
    light: "#0ddfff",
    mid: "#1fd093",
    dark: "#30c027",
  });
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [selectedThemeImage, setSelectedThemeImage] = useState("t2");

  const userId = Cookies.get("userId");

  const handleImageClick = (tid) => {
    setSelectedThemeImage(tid);
  };

  useEffect(() => {
    // Get theme data from Session Storage
    const savedTheme = sessionStorage.getItem("theme");

    if (savedTheme) setSelectedTheme(savedTheme);

    window.addEventListener("beforeunload", clearSessionStorage);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, []);

  // Add theme data to Session Storage
  const handleClick = () => {
    sessionStorage.setItem("theme", selectedTheme);
  };

  // Clear theme data from Session Storage
  const clearSessionStorage = () => {
    sessionStorage.removeItem("theme");
  };

  // Retrieve data from session storage
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");
  const username = sessionStorage.getItem("userName");
  const image = sessionStorage.getItem("image");
  const bio = sessionStorage.getItem("bio");
  const country = sessionStorage.getItem("country");
  const city = sessionStorage.getItem("city");
  const position = sessionStorage.getItem("position");
  const workplace = sessionStorage.getItem("workplace");

  const postDetails = async () => {
    try {
      const data = {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        username: username,
        bio: bio,
        city: city,
        country: country,
        workPlace: workplace,
        position: position,
        color: {
          light: selectedTheme.light,
          mid: selectedTheme.mid,
          dark: selectedTheme.dark,
        },
      };
      await axios.post(`${API_DOMAIN}/get/started`, data, {
        headers: { "Content-Type": "application/json" },
      });
      const themeArray = [
        selectedTheme.light,
        selectedTheme.mid,
        selectedTheme.dark,
      ];
      Cookies.set("theme", JSON.stringify(themeArray), {
        expires: 1 / 12,
      });
    } catch (error) {
      setIsConsoleError(true);
      setConsoleError(error.message);
    }
  };

  // Send profile photo to API
  const postPhoto = async () => {
    try {
      if (image) {
        const croppedImageBlob = await fetch(image).then((res) => res.blob());

        // Create a new File object from the Blob
        const imageFile = new File([croppedImageBlob], "croppedImage.png", {
          type: "image/png",
        });

        const formData = new FormData();
        formData.append("image", imageFile);
        formData.append("fk_user_id", userId);

        await axios.post(`${API_DOMAIN}/profile/image/upload`, formData);
      } else {
        return;
      }
    } catch (error) {
      setIsConsoleError(true);
      setConsoleError(error.message);
    }
  };

  const handleSubmit = async () => {
    try {
      await Promise.all([postDetails(), postPhoto()]);
      window.location.href = `/@${username}`;
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  return (
    <>
      <div
        className="theme-container"
        style={{
          background: `linear-gradient(to bottom, ${selectedTheme.light}, ${selectedTheme.dark})`,
        }}
      >
        <div className="theme-wrapper">
          <div className="theme-header">
            <StepBar num={4} selectedTheme={selectedThemeImage} />
          </div>
          <div className="theme-body">
            <div className="theme-topic">
              Let's craft your Tinly experience together.
            </div>

            <div className="theme-sub-topic">
              <h>Set a Color Theme</h>
            </div>
            <div className="theme-select">
              <input
                type="radio"
                name="slider"
                id="t1"
                checked={selectedThemeImage === "t1"}
                onChange={() => handleImageClick("t1")}
                onClick={() =>
                  setSelectedTheme({
                    light: "#e3b0e3",
                    mid: "#d0637f",
                    dark: "#bc151b",
                  })
                }
                hidden
              />
              <input
                type="radio"
                name="slider"
                id="t2"
                checked={selectedThemeImage === "t2"}
                onChange={() => handleImageClick("t2")}
                onClick={() =>
                  setSelectedTheme({
                    light: "#0ddfff",
                    mid: "#1fd093",
                    dark: "#30c027",
                  })
                }
                hidden
              />
              <input
                type="radio"
                name="slider"
                id="t3"
                checked={selectedThemeImage === "t3"}
                onChange={() => handleImageClick("t3")}
                onClick={() =>
                  setSelectedTheme({
                    light: "#f0ccd2",
                    mid: "#8494cd",
                    dark: "#185cc8",
                  })
                }
                hidden
              />

              <div className="themes">
                <label htmlFor="t1" id="theme-red">
                  <div className="card">
                    <img src={t1} alt="" onClick={handleClick} />
                  </div>
                </label>
                <label htmlFor="t2" id="theme-green">
                  <div className="card">
                    <img src={t2} alt="" onClick={handleClick} />
                  </div>
                </label>
                <label htmlFor="t3" id="theme-blue">
                  <div className="card">
                    <img src={t3} alt="" onClick={handleClick} />
                  </div>
                </label>
              </div>
              <div className="theme-note">You can set more colors later.</div>
            </div>

            <div className="theme-button">
              <Link to="/demo">
                <button className="btn-prev" onClick={handleClick}>
                  {" "}
                  &lt; Previous{" "}
                </button>
              </Link>
              <button
                className={`btn-save ${
                  selectedThemeImage === "t1"
                    ? "btn-t1"
                    : selectedThemeImage === "t3"
                    ? "btn-t3"
                    : "btn-t2"
                }`}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
};

export default SetTheme;
