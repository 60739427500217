import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoutes = () => {
  const token = Cookies.get('token');
  if(token){
    return <Outlet/>
  }
  else{
    return <Navigate to = "/"/>
  }
};
export default PrivateRoutes;
