import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { BsThreeDots } from "react-icons/bs";
import { TbFileUpload } from "react-icons/tb";
import CloseButton from "react-bootstrap/CloseButton";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import Cookies from "js-cookie";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";

export default function CustomLinkOption({
  customId,
  fetchCustomLinks,
  addedCustomLinks,
  customImages,
}) {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [customLinks, setCustomLinks] = useState("");
  const [isValidCustomLinks, setIsValidCustomLinks] = useState(false);
  const [customLinkError, setCustomLinkError] = useState("");
  const [customLinkTitle, setCustomLinkTitle] = useState("");
  const [customLinkTitleError, setCustomLinkTitleError] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [action, setAction] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isCLChanged, setIsCLChanged] = useState(false);
  const [isCustomLinkTitleEntered, setIsCustomLinkTitleEntered] =
    useState(false);
  const customLinkUrlRef = useRef(null);
  const customLinkTitleRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const userId = Cookies.get("userId");

  useEffect(() => {
    fetchCustomLinkOption();
    fetchCustomImage();
  }, [customImages, customId]);

  const handleCustomLinks = (e) => {
    const enteredCustomLink = e.target.value;
    const urlCheck =
      /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    setCustomLinks(enteredCustomLink);
    if (enteredCustomLink.trim() !== " ") {
      setIsValidCustomLinks(urlCheck.test(enteredCustomLink));
      setCustomLinkError("Enter a valid URL (https:// or http://)");
    } else {
      setIsValidCustomLinks(urlCheck.test(enteredCustomLink));
      setCustomLinkError("");
    }
  };

  const handleCustomTitle = (e) => {
    const enteredCustomTitle = e.target.value;
    setCustomLinkTitle(enteredCustomTitle);
    if (enteredCustomTitle.trim()) {
      setCustomLinkTitleError("");
    }
  };

  const fetchCustomImage = () => {
    const imageUrl = customImages[customId];
    if (imageUrl) {
      setImage(imageUrl);
    } else {
      setImage("");
    }
  };

  const fetchCustomLinkOption = () => {
    if (addedCustomLinks) {
      const filteredLink = addedCustomLinks.find(
        (link) => link.cId === customId
      );
      if (filteredLink) {
        setCustomLinks(filteredLink.customUrl);
        setCustomLinkTitle(filteredLink.customTitle);
      }
    }
  };

  useEffect(() => {
    if (isValidCustomLinks && customLinkUrlRef.current) {
      customLinkUrlRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    if (isCustomLinkTitleEntered && customLinkTitleRef.current) {
      customLinkTitleRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isValidCustomLinks, isCustomLinkTitleEntered]);

  const updateCustomLinks = async () => {
    setIsLoading(true);
    try {
      if (!customLinks.trim()) {
        setCustomLinkError("Website URL is required");
        setIsValidCustomLinks(false);
        return { isUploaded: false };
      } else if (!customLinkTitle.trim()) {
        setCustomLinkTitleError("Website Title is required");
        setIsCustomLinkTitleEntered(false);
        customLinkTitleRef.current.scrollIntoView({ behavior: "smooth" });
        return { isUploaded: false };
      } else {
        const formData = new FormData();
        formData.append("customLinkId", customId);
        formData.append("customLinks", customLinks);
        formData.append("title", customLinkTitle);
        formData.append("userId", userId);

        let imageToSend;
        if (selectedFile) {
          imageToSend = selectedFile;
        } else if (image) {
          imageToSend = image;
        } else {
          imageToSend = "";
        }

        formData.append("image", imageToSend);
        const response = await axios.put(
          `${API_DOMAIN}/customlinks/update`,
          formData
        );

        const result = response.data;
        if (result.success) {
          return { isUploaded: true };
        } else {
          const isNotFound = result.code === 404;
          if (isNotFound) {
            setIsConsoleError(true);
            setConsoleError(result.error);
            return { isUploaded: false };
          } else if (result.error === "URL is blacklisted") {
            setIsValidCustomLinks(false);
            setCustomLinkError("Restricted URL. Try another one.");
            return { isUploaded: false };
          } else {
            setIsValidCustomLinks(false);
            setCustomLinkError(result.error);
            return { isUploaded: false };
          }
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCustomLinks = async () => {
    try {
      const response = await axios.delete(`${API_DOMAIN}/customlinks/delete`, {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userId: userId,
          customLinkId: customId,
        },
      });

      const result = response.data;
      if (result.success) {
        setIsCLChanged(true);
        setIsVisible(true);
        setAction("deleted");
        fetchCustomLinks();
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(URL.createObjectURL(image));
        setSelectedFile(image);
      };
      reader.readAsDataURL(image);
    }
  };

  const handleChangeImage = (e) => {
    const newImage = e.target.files[0];
    if (newImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(URL.createObjectURL(newImage)); // Update the image preview
        setSelectedFile(newImage); // Update the selected file
      };
      reader.readAsDataURL(newImage);
    }
  };

  const handleRemoveImage = () => {
    setImage(""); // Clear the displayed image
    setSelectedFile(""); // Reset selectedFile state to empty string or null
  };

  const handleUpdate = async (close) => {
    const result = await updateCustomLinks();
    if (result && result.isUploaded) {
      fetchCustomLinks();
      setIsCLChanged(true);
      setIsVisible(true);
      setAction("updated");
      close();
    }
  };

  const handleDelete = (close) => {
    deleteCustomLinks();
    close();
  };

  return (
    <>
      <Popup
        trigger={
          <div className="custom-options">
            <BsThreeDots />
          </div>
        }
        modal
        nested
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="sm-popup">
            <div className="smp-header">
              <div className="smp-close">
                <button className="smp-close-btn" onClick={() => close()}>
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>
              <div className="smp-title">
                <h> {customLinkTitle} </h>
              </div>
            </div>
            <div className="custom-body">
              <div className="input-sec">
                <label>Website URL</label>
                <input
                  type="text"
                  className="txt-url"
                  placeholder="https://url or http://url"
                  value={customLinks}
                  onChange={handleCustomLinks}
                  ref={customLinkUrlRef}
                  required
                />
                {isValidCustomLinks ? (
                  ""
                ) : (
                  <div className="error-msg-custom-link">{customLinkError}</div>
                )}
              </div>

              <div className="input-sec">
                <label>Website Image or Logo</label>
                <div className="add-img-sec">
                  {image ? (
                    <div className="uploaded-img-container">
                      <img
                        src={image}
                        alt="Website Logo"
                        className="uploaded-img"
                      />
                      <div className="btn-group">
                        <button
                          className="btn-change"
                          onClick={() => fileInputRef.current.click()}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            className="input-img"
                            ref={fileInputRef}
                            onChange={handleChangeImage}
                            hidden
                          />
                          Change Image
                        </button>
                        <button
                          className="btn-remove"
                          onClick={handleRemoveImage}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        document.querySelector(".input-img").click()
                      }
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="input-img"
                        onChange={handleImageChange}
                        hidden
                      />
                      <div className="upload-area">
                        <TbFileUpload className="upload-icon" />
                        <p className="upload-desc">
                          Upload a picture in BMP, JPG, JPEG or PNG format.
                          <br /> Maximum file size: 100MB
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="input-sec">
                <label>Website Title</label>
                <input
                  type="text"
                  className="txt-url"
                  placeholder="Title"
                  value={customLinkTitle}
                  onChange={handleCustomTitle}
                  ref={customLinkTitleRef}
                  required
                />
                {isCustomLinkTitleEntered ? (
                  ""
                ) : (
                  <div className="error-msg-custom-link">
                    {customLinkTitleError}
                  </div>
                )}
              </div>
              <div className="bio-update-delete">
                <button
                  className="bio-delete"
                  onClick={() => handleDelete(close)}
                >
                  Delete
                </button>
                <button
                  className="bio-update"
                  onClick={() => handleUpdate(close)}
                >
                  {isLoading ? <div className="spinner"></div> : "Update"}
                </button>
              </div>
            </div>
          </div>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
      <ConfirmationMessage
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        isCLChanged={isCLChanged}
        action={action}
      />
    </>
  );
}
