import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectRoute = () => {
  const username = Cookies.get("username");

  if (username) {
    return <Outlet />;
  } else {
    return <Navigate to={`/@${username}`} />;
  }
};

export default ProtectRoute;
