import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'react-image-crop/dist/ReactCrop.css'
import Cookies from 'js-cookie'
import DefaultProfile from "./Pages/DefaultProfile/DefaultProfile";
import MainLogin from "./Pages/MainLogin/MainLogin";
import GetStarted from "./Pages/GetStarted/GetStarted";
import LoginUser from "./Pages/LoginUser/LoginUser";
import SetBio from "./Pages/SetBio/SetBio";
import SetDemo from "./Pages/SetDemo/SetDemo";
import SetTheme from "./Pages/SetTheme/SetTheme";
import GuestView from "./Pages/GuestView/GuestView";
import PrivateRoutes from "./PrivateRouter";
import ProtectRoute from './ProtectRoute'
import DirectNotice from "./Components/DirectNotice/DirectNotice";
import NotFound from "./Components/DirectNotice/NotFound";
import "./App.css";

function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<MainLogin guestUserAdd = {Cookies.get('guestUserAdd')} guestReport = {Cookies.get('guestReport')} guestUsername = {Cookies.get('guestUsername')} loginToView = {Cookies.get("loginToView")} site = {Cookies.get("site")}  iv = {Cookies.get("iv")}/>}> </Route>
          <Route path="/guest/:guestUsername" element={<GuestView token = {Cookies.get('token')} userId = {Cookies.get('userId')}/>} > </Route>
          <Route path = "/direct" element={<DirectNotice/>}> </Route>
          <Route path = "/notfound" element={<NotFound/>}> </Route>
          <Route element = {<PrivateRoutes/>}>
            <Route path="/:usernameOfTheUser" element={<DefaultProfile token = {Cookies.get('token')} userId = {Cookies.get('userId')} userName = {Cookies.get('username')} themeOfTheUser={Cookies.get('theme')}/>} > </Route>
            <Route element = {<ProtectRoute/>}>
              <Route path="/getStart" element={<GetStarted token = {Cookies.get('token')} userId = {Cookies.get('userId')} userName = {Cookies.get('username')}/>} > </Route>   
              <Route path="/uname" element={<LoginUser token = {Cookies.get('token')} userId = {Cookies.get('userId')} userName = {Cookies.get('username')}/>} > </Route>
              <Route path="/bio" element={<SetBio token = {Cookies.get('token')} userId = {Cookies.get('userId')}/>} > </Route>
              <Route path="/demo" element={<SetDemo token = {Cookies.get('token')} userId = {Cookies.get('userId')}/>} > </Route>
              <Route path="/theme" element={<SetTheme token = {Cookies.get('token')} userId = {Cookies.get('userId')}/>} > </Route>
              <Route path="*" element={<div> Page Not Found!!! </div>} > </Route>
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}
export default App;
