import React, { useState, useEffect } from "react";
import API_DOMAIN from "../../ApiConfig";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import { CiFlag1 } from "react-icons/ci";
import "./ReportProfile.css";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

function ReportProfile({ guestUsername }) {
  const [isQuickReport, setIsQuickReport] = useState(false);
  const [isInappropriateContent, setIsInappropriateContent] = useState(false);
  const [isViolence, setIsViolence] = useState(false);
  const [isNudity, setIsNudity] = useState(false);
  const [isSpam, setIsSpam] = useState(false);
  const [isFraud, setIsFraud] = useState(false);
  const [isIntellectualProperty, setIsIntellectualProperty] = useState(false);
  const [isHateSpeech, setIsHateSpeech] = useState(false);
  const [isMisinformation, setIsMisinformation] = useState(false);
  const [isImpersonation, setIsImpersonation] = useState(false);
  const [isTerrorism, setIsTerrorism] = useState(false);
  const [isDrugContent, setIsDrugContent] = useState(false);
  const [isChildExploitation, setIsChildExploitation] = useState(false);
  const [isPrivacyViolation, setIsPrivacyViolation] = useState(false);
  const [isOtherContent, setIsOtherContent] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedSubType, setSelectedSubType] = useState("");
  const [otherReportText, setOtherReportText] = useState("");
  const [isReportSubmit, setIsReportSubmit] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [consoleError, setConsoleError] = useState("");

  const userId = Cookies.get("userId");

  const handleReportOnClick = () => {
    if (userId) {
      setIsQuickReport(false);
      setIsPopupOpen(true);
      setIsFooterVisible(false);
    } else {
      const guestReport = "report";
      Cookies.set("guestReport", guestReport);
      Cookies.set("guestUsername", guestUsername);
      window.location.href = "/";
    }
  };

  useEffect(() => {
    const fetchUserId = async () => {
      if (userId === "undefined") {
        const userName = Cookies.get("username");
        const response = await axios.get(
          `${API_DOMAIN}/login/detail/${userName}`
        );
        const data = response.data;
        const userId = data.userId;
        Cookies.set("userId", userId);
      }
    };
    fetchUserId();
  }, [userId]);

  const postReport = async () => {
    try {
      const response = await axios.post(
        "https://api.clickup.com/api/v2/list/901802392988/task",
        {
          name: `Report- ${selectedType}`,
          description: `Type: ${selectedType}\nSubtype: ${selectedSubType}\nReporter Id: ${userId}\nRespondent Username: ${guestUsername}\nOther Content: ${otherReportText}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "pk_89423082_25QXEF1R3O4XXGP5YGESXGAFFCPOZQND",
          },
        }
      );
      if (response.status === 200) {
        setIsReportSubmit(true);
        setIsVisible(true);
        await closeModalAfterDelay();
        return { isSuccess: true };
      }
    } catch (error) {
      setIsConsoleError(true);
      setConsoleError(error);
    }
  };

  const handleTypeSelection = (type) => {
    setSelectedType(type);
    setSelectedSubType("");
  };

  const handleSubtypeSelection = (subtype) => {
    setSelectedSubType(subtype);
  };

  const closeModalAfterDelay = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setIsPopupOpen(false);
        resolve();
      }, 3000);
      setIsReportSubmit(true);
      setIsVisible(true);
    });
  };

  const handleSubmitReport = (close) => {
    if (selectedType && selectedSubType) {
      postReport();
      close();
    } else {
      setIsConsoleError(true);
      setConsoleError(
        "You must select a reason to proceed with submitting your report."
      );
    }
  };

  return (
    <>
      <div className="report-icon" onClick={handleReportOnClick}>
        <CiFlag1 />
      </div>
      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
        nested
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="report-profile-popup">
            <div className="rpp-header">
              <button className="rpp-close" onClick={() => close()}>
                <IoMdClose />
              </button>
              <div className="rpp-title">Report Profile</div>
            </div>
            <div className="rpp-body">
              <div className="report-profile-type">
                <div className="report-profile-header">
                  <div
                    className="report-title"
                    onClick={() => {
                      setIsQuickReport(!isQuickReport);
                      setIsInappropriateContent(false);
                      setIsViolence(false);
                      setIsNudity(false);
                      setIsSpam(false);
                      setIsFraud(false);
                      setIsIntellectualProperty(false);
                      setIsHateSpeech(false);
                      setIsMisinformation(false);
                      setIsImpersonation(false);
                      setIsTerrorism(false);
                      setIsDrugContent(false);
                      setIsChildExploitation(false);
                      setIsPrivacyViolation(false);
                      setIsOtherContent(false);
                      setIsFooterVisible(false);
                    }}
                  >
                    Quick Report
                  </div>
                  <div className="report-type-icon">
                    {isQuickReport ? (
                      <MdOutlineKeyboardArrowDown
                        onClick={() => {
                          setIsQuickReport(!isQuickReport);
                          setIsInappropriateContent(false);
                          setIsViolence(false);
                          setIsNudity(false);
                          setIsSpam(false);
                          setIsFraud(false);
                          setIsIntellectualProperty(false);
                          setIsHateSpeech(false);
                          setIsMisinformation(false);
                          setIsImpersonation(false);
                          setIsTerrorism(false);
                          setIsDrugContent(false);
                          setIsChildExploitation(false);
                          setIsPrivacyViolation(false);
                          setIsOtherContent(false);
                          setIsFooterVisible(false);
                        }}
                      />
                    ) : (
                      <MdOutlineKeyboardArrowRight
                        onClick={() => {
                          setIsQuickReport(true);
                          setIsInappropriateContent(false);
                          setIsViolence(false);
                          setIsNudity(false);
                          setIsSpam(false);
                          setIsFraud(false);
                          setIsIntellectualProperty(false);
                          setIsHateSpeech(false);
                          setIsMisinformation(false);
                          setIsImpersonation(false);
                          setIsTerrorism(false);
                          setIsDrugContent(false);
                          setIsChildExploitation(false);
                          setIsPrivacyViolation(false);
                          setIsOtherContent(false);
                          setIsFooterVisible(false);
                        }}
                      />
                    )}
                  </div>
                </div>
                {isQuickReport && (
                  <div
                    className={`quick-report-body ${
                      isQuickReport ? "open" : ""
                    }`}
                  >
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        handleTypeSelection("Inappropriate Content");
                        setIsInappropriateContent(!isInappropriateContent);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Inappropriate Content
                      {isInappropriateContent ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isInappropriateContent && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Profanity");
                            }}
                          />
                          <div className="ic-radio-btn"> Profanity </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Offensive Language");
                            }}
                          />
                          <div className="ic-radio-btn">
                            {" "}
                            Offensive Language{" "}
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Hate Speech");
                            }}
                          />
                          <div className="ic-radio-btn"> Hate Speech </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Harassment or Bullying");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Harassment or Bullying
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Violence");
                        setIsInappropriateContent(false);
                        setIsViolence(!isViolence);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Violence
                      {isViolence ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isViolence && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Threats");
                            }}
                          />
                          <div className="ic-radio-btn"> Threats </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Violent Acts");
                            }}
                          />
                          <div className="ic-radio-btn"> Violent Acts </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Graphic Violence");
                            }}
                          />
                          <div className="ic-radio-btn"> Graphic Violence </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Promoting Self-Harm");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Promoting Self-Harm
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Nudity");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(!isNudity);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Nudity
                      {isNudity ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isNudity && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Explicit Sexual Content");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Explicit Sexual Content
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Adult Content");
                            }}
                          />
                          <div className="ic-radio-btn"> Adult Content </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Pornographic Material");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Pornographic Material
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Spam");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(!isSpam);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Spam
                      {isSpam ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isSpam && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Unsolicited Advertising");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Unsolicited Advertising
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Fake Profiles");
                            }}
                          />
                          <div className="ic-radio-btn"> Fake Profiles </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Phishing Attempts");
                            }}
                          />
                          <div className="ic-radio-btn">
                            {" "}
                            Phishing Attempts{" "}
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              handleSubtypeSelection("Malicious Links");
                            }}
                          />
                          <div className="ic-radio-btn">Malicious Links</div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Fraud or Scam");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(!isFraud);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Fraud or Scam
                      {isFraud ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isFraud && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Deceptive Practices");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Deceptive Practices
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Financial Fraud");
                            }}
                          />
                          <div className="ic-radio-btn"> Financial Fraud </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Identity Theft");
                            }}
                          />
                          <div className="ic-radio-btn"> Identity Theft </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Intellectual Property Violation");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(!isIntellectualProperty);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Intellectual Property Violation
                      {isIntellectualProperty ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isIntellectualProperty && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Copyright Infringement");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Copyright Infringement
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Trademark Violation");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Trademark Violation
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Hate Speech");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(!isHateSpeech);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Hate Speech
                      {isHateSpeech ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isHateSpeech && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType(
                                "Discrimination Based on Race, Ethnicity, Religion,Gender, Sexual Orientation"
                              );
                            }}
                          />
                          <div className="ic-radio-btn">
                            Discrimination Based on Race, Ethnicity, Religion,
                            Gender, Sexual Orientation
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Misinformation");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(!isMisinformation);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Misinformation
                      {isMisinformation ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isMisinformation && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("False Information");
                            }}
                          />
                          <div className="ic-radio-btn">
                            {" "}
                            False Information{" "}
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Fake News");
                            }}
                          />
                          <div className="ic-radio-btn"> Fake News </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Impersonation");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(!isImpersonation);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Impersonation
                      {isImpersonation ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isImpersonation && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Fake Accounts");
                            }}
                          />
                          <div className="ic-radio-btn"> Fake Accounts </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType(
                                "Pretending to be Someone Else"
                              );
                            }}
                          />
                          <div className="ic-radio-btn">
                            Pretending to be Someone Else
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Terrorism");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(!isTerrorism);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Terrorism
                      {isTerrorism ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isTerrorism && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType(
                                "Promoting Terrorist Activities"
                              );
                            }}
                          />
                          <div className="ic-radio-btn">
                            Promoting Terrorist Activities
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType(
                                "Recruitment for Terrorist Groups"
                              );
                            }}
                          />
                          <div className="ic-radio-btn">
                            Recruitment for Terrorist Groups
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Drug-Related Content");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(!isDrugContent);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Drug-Related Content
                      {isDrugContent ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isDrugContent && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Promoting or Selling Drugs");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Promoting or Selling Drugs
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Illegal Substance Use");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Illegal Substance Use
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Child Exploitation");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(!isChildExploitation);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Child Exploitation
                      {isChildExploitation ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isChildExploitation && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Child Abuse Material");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Child Abuse Material
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Exploitation of Minors");
                            }}
                          />
                          <div className="ic-radio-btn">
                            Exploitation of Minors
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Privacy Violation");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(!isPrivacyViolation);
                        setIsOtherContent(false);
                        setIsFooterVisible(true);
                      }}
                    >
                      Privacy Violation
                      {isPrivacyViolation ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isPrivacyViolation && (
                      <div className="choose-inappropriate-content">
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType(
                                "Sharing Personal Information Without Consent"
                              );
                            }}
                          />
                          <div className="ic-radio-btn">
                            Sharing Personal Information Without Consent
                          </div>
                        </div>
                        <div className="ic-radio">
                          <input
                            type="radio"
                            name="ic-select"
                            onClick={() => {
                              setSelectedSubType("Doxing");
                            }}
                          />
                          <div className="ic-radio-btn"> Doxing </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="quick-report-sub-title"
                      onClick={() => {
                        setSelectedType("Other");
                        setIsInappropriateContent(false);
                        setIsViolence(false);
                        setIsNudity(false);
                        setIsSpam(false);
                        setIsFraud(false);
                        setIsIntellectualProperty(false);
                        setIsHateSpeech(false);
                        setIsMisinformation(false);
                        setIsImpersonation(false);
                        setIsTerrorism(false);
                        setIsDrugContent(false);
                        setIsChildExploitation(false);
                        setIsPrivacyViolation(false);
                        setIsOtherContent(!isOtherContent);
                        setIsFooterVisible(true);
                      }}
                    >
                      Other
                      {isOtherContent ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowRight />
                      )}
                    </div>
                    {isOtherContent && (
                      <div className="choose-inappropriate-content">
                        <div className="rpp-body-other-canvas">
                          <textarea
                            rows={3}
                            cols={25}
                            className="report-other-body"
                            value={otherReportText}
                            onChange={(e) => {
                              setOtherReportText(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="report-profile-type">
                <div className="report-profile-header">
                  <div
                    className="report-title"
                    onClick={() =>
                      window.open(
                        "https://support.mavicsoft.com/abuse/",
                        "_blank"
                      )
                    }
                  >
                    Advance Report
                  </div>
                </div>
              </div>
              {isFooterVisible && (
                <div className="rp-footer">
                  <div
                    className="report-btn"
                    onClick={() => {
                      handleSubmitReport(close);
                    }}
                  >
                    Report
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConfirmationMessage
        isReportSubmit={isReportSubmit}
        respondentUsername={guestUsername}
        setIsVisible={setIsVisible}
        isVisible={isVisible}
      />
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}

export default ReportProfile;
