import React, { useState, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import "./StepBar.css";
import API_DOMAIN from "../../ApiConfig";

export default function StepBar({ num, selectedTheme }) {
  const [isSkipClicked, setIsSkipClicked] = useState(false);
  const modalRef = useRef(null);
  const [nameOfTheUser, setNameOfTheUser] = useState("");

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsSkipClicked(false);
      }
    };

    if (isSkipClicked) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSkipClicked]);

  const userId = Cookies.get("userId");

  const fetchUserDetails = async () => {
    const response = await fetch(`${API_DOMAIN}/user?userId=${userId}`);
    const result = await response.json();
    if (result.success) {
      const { userName } = jwtDecode(result.token);
      setNameOfTheUser(userName);
    }
  };

  return (
    <div className="progress-header">
      <div className="ul-progress">
        <div
          className={`ul-progress-box ${
            selectedTheme === "t1"
              ? "ulb-t1"
              : selectedTheme === "t3"
              ? "ulb-t3"
              : "ulb-t2"
          }`}
        >
          <div className="ul-box1"></div>
          <div className="ul-box2"></div>
          <div className="ul-box3"></div>
          <div className="ul-box4"></div>
        </div>

        <button
          className="ul-skip"
          onClick={() => {
            setIsSkipClicked(true);
            fetchUserDetails();
          }}
        >
          Skip
        </button>
      </div>

      {isSkipClicked && (
        <div className="skip-modal" ref={modalRef}>
          <div className="lcm-msg">
            Are you sure? Your changes will not be saved.
          </div>
          <div className="skip-buttons">
            <div
              className="skip-cancel-btn"
              onClick={() => setIsSkipClicked(false)}
            >
              Cancel
            </div>
            <a href={`/@${nameOfTheUser}`} className="skip-logout-btn">
              Ok
            </a>
          </div>
        </div>
      )}

      <div className="ul-progress-num">
        <h>{num}/4</h>
      </div>
    </div>
  );
}
