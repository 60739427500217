import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import Cookies from "js-cookie";
import "./ManageCircle.css";
import ModifyCircle from "../AddNewCircle/ModifyCircle";
import API_DOMAIN from "../../ApiConfig";
import { jwtDecode } from "jwt-decode";
import MoveUser from "../MoveUser/MoveUser";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import { FaUserCircle } from "react-icons/fa";

export default function ManageCircle({
  closeManageCircle,
  circleId,
  circleName,
  fetchCirclesAvailable,
  followerCountChanged,
  isCircleClicked,
  circleMemberCount,
  isVisible,
  setIsVisible,
  setIsUsersDeleted,
  setIsSaveChanges,
  setIsUsersMoved,
  moveCircleName,
  setIsAddCircle,
  setIsCircleDeleted,
  setDeleteCircleName,
  setCloseAddNew
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [isRecentConTab, setIsRecentConTab] = useState(true);
  const [userImages, setUserImages] = useState([]);
  const [isShowUserConnect, setIsShowUserConnect] = useState(false);
  const [userIdsToMove, setUserIdsToMove] = useState([]);
  const [connections, setConnections] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [action, setAction] = useState("");
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isConnectionFetched, setIsConnectionFetched] = useState(false);

  const userId = Cookies.get("userId");
  const navigate = useNavigate();

  useEffect(() => {
    if (followerCountChanged && isCircleClicked) {
      fetchFollowers();
    }
  }, [followerCountChanged, isCircleClicked]);

  const fetchFollowers = async () => {
    try {
      const response = await axios.get(
        `${API_DOMAIN}/user/circle/view/${userId}`,
        {
          params: { circleName },
        }
      );
      const result = response.data;
      if (result.success) {
        const filteredNames = jwtDecode(result.token);
        const followers = filteredNames.users;

        const followerNames = followers.map((link) => ({
          uname: link.fullName,
          uId: link.userID,
          userName: link.username,
        }));
        // Sort the followerNames array based on uname in alphabetical order
        const sortedNames = followerNames.sort((a, b) =>
          a.uname.localeCompare(b.uname)
        );

        setConnections(sortedNames);

        sortedNames.forEach((followerName) => {
          fetchProfileImage(followerName.uId);
        });
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const fetchProfileImage = async (idOfUser) => {
    try {
      const response = await axios.get(`${API_DOMAIN}/profile/image/view`, {
        params: { fk_user_id: idOfUser },
        responseType: "blob",
      });

      const contentType = response.headers["content-type"];
      if (contentType && contentType.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(response.data);
        setUserImages((prevImages) => ({
          ...prevImages,
          [idOfUser]: imageUrl,
        }));
      } else {
        setUserImages((prevImages) => ({
          ...prevImages,
          [idOfUser]: null,
        }));
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsConnectionFetched(true);
    }
  };

  const closeModalAfterDelay = () => {
    return new Promise(() => {
      fetchFollowers();
      setIsUsersDeleted(true);
      setIsVisible(true);
      setIsUsersMoved(false);
      setIsSaveChanges(false);
      setIsAddCircle(false);
      setIsCircleDeleted(false);
    });
  };

  const deleteFollower = async () => {
    try {
      if (userIdsToMove.length === 0) {
        setIsUserSelected(true);
        setAction("Remove");
      } else {
        await axios.delete(`${API_DOMAIN}/user/circle/delete/${userId}`, {
          data: {
            users: userIdsToMove,
            circleName,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        fetchCirclesAvailable();
        closeModalAfterDelay();
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleUserSelect = (userId, isSelected) => {
    setUserIdsToMove((prevUserIds) => {
      if (isSelected) {
        return [...prevUserIds, { targetUserId: userId }];
      } else {
        return prevUserIds.filter((user) => user.targetUserId !== userId);
      }
    });
  };

  useEffect(() => {
    if (userIdsToMove.length === 0) {
      setIsButtonActive(false);
    } else {
      setIsButtonActive(true);
    }
    setIsSelectAll(
      userIdsToMove.length === connections.length && connections.length > 0
    );
  }, [userIdsToMove, connections.length]);

  const handleSelectAll = (checked) => {
    setIsSelectAll(checked);
    if (checked) {
      setUserIdsToMove(connections.map((link) => ({ targetUserId: link.uId })));
    } else {
      setUserIdsToMove([]);
    }
  };

  const filteredConnections = connections.filter((connection) =>
    connection.uname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (isShowUserConnect) {
    return (
      <MoveUser
        isPopup={setIsShowUserConnect}
        moveUserIds={userIdsToMove}
        closeManageCircleModal={closeManageCircle}
        fetchCircles={fetchCirclesAvailable}
        nameOfCircle={circleName}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        setIsUsersMoved={setIsUsersMoved}
        moveCircleName={moveCircleName}
        setIsSaveChanges={setIsSaveChanges}
        setIsAddCircle={setIsAddCircle}
        setIsUsersDeleted={setIsUsersDeleted}
        setIsCircleDeleted={setIsCircleDeleted}
      />
    );
  }

  const handleMoveUserModal = () => {
    if (userIdsToMove.length === 0) {
      setIsUserSelected(true);
      setAction("Move");
    } else {
      setIsShowUserConnect(true);
    }
  };

  return (
    <>
      <div className="manage-circles-modal">
        <div className="mcm-header">
          <button
            className="mcm-close-btn"
            onClick={() => {
              closeManageCircle(false);
            }}
          >
            <RxCross2 />
          </button>
          <div className="mcm-header-sec">
            <div className="mcm-settings-icon">
              <ModifyCircle
                idCircle={circleId}
                nameCircle={circleName}
                fetchAvailableCircles={fetchCirclesAvailable}
                closeManageCircle={closeManageCircle}
                setIsVisible={setIsVisible}
                setIsSaveChanges={setIsSaveChanges}
                setIsAddCircle={setIsAddCircle}
                setIsUsersDeleted={setIsUsersDeleted}
                setIsUsersMoved={setIsUsersMoved}
                setIsCircleDeleted={setIsCircleDeleted}
                setDeleteCircleName={setDeleteCircleName}
                setCloseAddNew={setCloseAddNew}
              />
            </div>
            <div className="mcm-title">{circleName}</div>
            <div className="mcm-con-count">{circleMemberCount}</div>
          </div>
        </div>

        <div className="mcm-body-search">
          <input
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder="Search..."
            className="mcm-search-input"
          />
        </div>

        <div className="mcm-body-tabs">
          {isConnectionFetched && (
            <button
              className={`mcm-tab-name ${
                isRecentConTab ? "con-tab-active" : ""
              }`}
              onClick={() => {
                setIsRecentConTab(true);
              }}
            >
              {circleMemberCount > 0 && "All"}
            </button>
          )}
        </div>
        {circleMemberCount > 0 ? (
          <div className="mcm-body-connections">
            {!isConnectionFetched ? (
              <div className="loader-class">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                <div className="mcm-select-all">
                  <input
                    type="checkbox"
                    className="mcm-check-box"
                    checked={isSelectAll}
                    onChange={(e) => {
                      handleSelectAll(e.target.checked);
                    }}
                  />
                  {isSelectAll ? (
                    <label>Deselect All</label>
                  ) : (
                    <label>Select All</label>
                  )}
                </div>
                {filteredConnections.length > 0 ? (
                  filteredConnections.map((link, index) => (
                    <div key={index} className="mcm-connection">
                      <input
                        type="checkbox"
                        className="mcm-check-box"
                        checked={userIdsToMove.some(
                          (user) => user.targetUserId === link.uId
                        )}
                        onChange={(e) => {
                          handleUserSelect(link.uId, e.target.checked);
                        }}
                      />
                      <div className="mcm-con-photo">
                        {userImages[link.uId] ? (
                          <img
                            src={userImages[link.uId]}
                            alt=""
                            style={{
                              width: "60px",
                              height: "60px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <div>
                            <FaUserCircle
                              style={{
                                width: "60px",
                                height: "60px",
                                justifyContent: "center",
                                alignItems: "center",
                                color: " #cfcfcf",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className="mcm-con-name"
                        onClick={() => {
                          navigate(`/guest/${link.userName}`);
                        }}
                      >
                        {link.uname}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No connections found</div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="mcm-body-connections">No connections added yet</div>
        )}

        {isConnectionFetched && circleMemberCount > 0 && (
          <div className="mcm-footer-btns">
            <button
              onClick={handleMoveUserModal}
              className={`mcm-footer-btn ${
                isButtonActive ? "active" : "disable"
              }`}
            >
              Move
            </button>
            <button
              onClick={deleteFollower}
              className={`mcm-footer-btn ${
                isButtonActive ? "active" : "disable"
              }`}
            >
              Remove
            </button>
          </div>
        )}
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
        closeNoSelectError={setIsUserSelected}
        isUserSelected={isUserSelected}
        action={action}
      />
    </>
  );
}
