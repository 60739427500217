import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import CloseButton from "react-bootstrap/CloseButton";
import Designation from "../../Assets/Designation.svg";
import API_DOMAIN from "../../ApiConfig";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import "./UserDesignation.css";
import InputInfo from "../InputInfo/InputInfo";
import axios from "axios";

export default function UserDesignation({ designation, fetchDesignation }) {
  const [position, setPosition] = useState("");
  const [inputPosition, setInputPosition] = useState("");
  const [isPosted, setIsPosted] = useState(false);
  const [desError, setDesError] = useState("");
  const [action, setAction] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userId = Cookies.get("userId");

  //fetch designation
  useEffect(() => {
    if (designation) {
      setPosition(designation);
      setIsPosted(true);
      setInputPosition(designation);
    }
  }, [designation]);

  //Post designation to API
  const postDesignation = async () => {
    try {
      const response = await axios.post(
        `${API_DOMAIN}/designation`,
        { userId, position },
        { headers: { "Content-Type": "application/json" } }
      );

      const result = response.data;
      if (result.success) {
        setIsPosted(true);
        return { isSuccess: true };
      } else {
        const isNotFound = result.code === 404;

        if (isNotFound) {
          setIsConsoleError(true);
          setConsoleError(result.error);
        } else {
          setDesError(result.error);
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  //Delete designation
  const deleteDesignation = async () => {
    try {
      await axios.delete(`${API_DOMAIN}/designation/delete`, {
        data: {
          userId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAction("delete");
      setIsVisible(true);
      setIsPosted(false);
      setPosition("");
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (close) => {
    if (!position.trim()) {
      setDesError("Designation is required.");
      return;
    }
    setIsLoading(true);
    const result = await postDesignation();
    if (result && result.isSuccess) {
      close();
      setAction("add");
      setIsVisible(true);
      fetchDesignation();
    }
  };

  const handleUpdate = async (close) => {
    if (!position.trim()) {
      setDesError("Designation is required.");
      return;
    }
    setIsLoading(true);
    const result = await postDesignation();
    if (result && result.isSuccess) {
      close();
      setAction("update");
      setIsVisible(true);
      fetchDesignation();
    }
  };

  const handleDelete = (close) => {
    deleteDesignation();
    close();
  };

  const validateDesignation = (e) => {
    const enteredDesignation = e.target.value;
    setPosition(enteredDesignation);
    const designation = enteredDesignation.trim();
    const designationRegex = /^[A-Za-z\s]{1,30}$/;

    if (designationRegex.test(designation)) {
      setDesError("");
    } else {
      setDesError("Enter a valid Designation");
    }
    if (!enteredDesignation) {
      setDesError("");
    }
  };

  return (
    <>
      <Popup
        trigger={
          <button className="demographic">
            <img src={Designation} alt="" className="demo-icon" />

            <span className="demo-title">
              {isPosted
                ? inputPosition
                  ? `${inputPosition.slice(0, 20)}${
                      inputPosition.length > 20 ? "..." : ""
                    }`
                  : "Designation"
                : "Designation"}
            </span>
          </button>
        }
        modal
        nested
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="designation-popup">
            <div className="designation-popup-header">
              <div className="designation-box">
                <div className="designation-close">
                  <button
                    className="designation-close-btn"
                    onClick={() => close()}
                  >
                    <CloseButton style={{ height: "5px", width: "5px" }} />
                  </button>
                </div>
                <div className="designation-title">
                  <div>Designation</div>
                </div>
              </div>
            </div>
            <div className="designation-body">
              <div className="type-designation">
                <input
                  type="text"
                  placeholder="Designation"
                  value={position}
                  onChange={validateDesignation}
                />
                <InputInfo Description={"Use only letters"} />
              </div>
              {desError && (
                <div className="des-pb-error-message">{desError}</div>
              )}
            </div>
            <div className="designation-footer">
              {!isPosted ? (
                <button
                  className="designation-add"
                  onClick={() => handleSubmit(close)}
                >
                  {isLoading ? <div className="spinner"></div> : "Add"}
                </button>
              ) : (
                <div className="designation-update-delete">
                  <button
                    className="designation-delete"
                    onClick={() => handleDelete(close)}
                  >
                    Delete
                  </button>
                  <button
                    className="designation-update"
                    onClick={() => handleUpdate(close)}
                  >
                    {isLoading ? <div className="spinner"></div> : "Update"}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConfirmationMessage
        functionAdd={action === "add"}
        functionDelete={action === "delete"}
        functionUpdate={action === "update"}
        nameOfFunction="Designation"
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}
