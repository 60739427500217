import React, { useState, useEffect, useRef } from "react";
import Lottie from "react-lottie";
import QrReader from "react-qr-scanner";
import { RxCross2 } from "react-icons/rx";
import Camera from "../../Assets/Camera.png";
import QrScanAnime from "../../Lottie/QRScan.json";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import "./QRReader.css";

export default function QRReader({ closeScanModal }) {
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: "user",
  });
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);
  const timeoutRef = useRef(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: QrScanAnime,
    renderSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Device detection and check the camera permission
  useEffect(() => {
    const checkPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        stream.getTracks().forEach((track) => track.stop());
        setIsPermissionDenied(false);
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          setVideoConstraints({ facingMode: { exact: "environment" } }); // Use back camera on mobile
        } else {
          setVideoConstraints({ facingMode: "user" }); // Use front camera on laptop/desktop
        }
      } catch (error) {
        if (
          error.name === "NotAllowedError" ||
          error.name === "PermissionDeniedError"
        ) {
          setIsPermissionDenied(true);
        } else if (error.name === "OverconstrainedError") {
          setVideoConstraints({ facingMode: "user" });
        } else {
          setIsConsoleError(true);
          setConsoleError(error.message);
        }
      }
    };

    checkPermission();
  }, []);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setIsTimeOut(true);
    }, 15000);
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const handleScan = (data) => {
    if (data) {
      const url = data.text;

      if (url.startsWith("https://tinly-front-dev.netfy.app/")) {
        clearTimeout(timeoutRef.current);
        setIsTimeOut(false);
        window.location.href = url;
      } else {
        setIsTimeOut(true);
      }
    }
  };

  const handleError = (error) => {
    if (
      error.name === "NotAllowedError" ||
      error.name === "PermissionDeniedError"
    ) {
      setIsPermissionDenied(true);
    } else if (error.name === "OverconstrainedError") {
      setVideoConstraints({ facingMode: "user" });
    } else if (error.name === "NotFoundError") {
      setIsConsoleError(true);
      setConsoleError("Camera not found. Connect a camera to your computer");
    } else {
      setIsConsoleError(true);
      setConsoleError(error.name);
    }
  };

  //prevent close modal when click inside
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleRetry = () => {
    setIsTimeOut(false);
    setIsPermissionDenied(false);

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsTimeOut(true);
    }, 15000);
  };

  return (
    <>
      <div className="scan-qr-popup" onClick={handleModalClick}>
        <div className="scan-qr-header">
          <div className="scan-qr-title">Scan your Friend's QR</div>
          <button
            className="scan-qr-close"
            onClick={() => {
              closeScanModal(false);
            }}
          >
            <RxCross2 />
          </button>
        </div>

        {isTimeOut && (
          <div className="scan-qr-error">
            {isPermissionDenied ? (
              <div className="scan-camera-error">
                <div className="scan-qr-error-image">
                  <img src={Camera} alt="camera-error" />
                </div>
                <div className="scan-qr-error-topic">Enable Camera Access</div>
                <div className="scan-qr-error-msg">
                  We need your permission to access the camera
                </div>
              </div>
            ) : (
              <>
                <div className="scan-qr-msg">Scan a Tinly QR code</div>
                <Lottie options={defaultOptions} className="scan-qr-anime" />
                <button className="scan-qr-btn" onClick={handleRetry}>
                  Try Again
                </button>
              </>
            )}
          </div>
        )}

        {!isTimeOut && (
          <div className="scan-qr-body">
            <div className="qr-reader">
              <QrReader
                delay={1000}
                onError={handleError}
                onScan={handleScan}
                constraints={{
                  video: videoConstraints,
                }}
                className="qr-scanner"
              />
            </div>
            <div className="qr-border"></div>
          </div>
        )}
        {!isTimeOut && (
          <div className="scan-qr-text">
            <div className="qr-reader-msg">Scanning</div>
            <div className="loading-dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        )}
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
    </>
  );
}
