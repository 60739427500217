import React, { useState } from "react";
import axios from "axios";
import { BsThreeDots } from "react-icons/bs";
import Popup from "reactjs-popup";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Cookies from "js-cookie";
import "./SitesOption.css";
import API_DOMAIN from "../../ApiConfig";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";

function SitesOption({ fetchSites, siteState, addedSiteLink, addedSiteTitle }) {
  const [siteLinkError, setSiteLinkError] = useState("");
  const [isValidSiteLink, setIsValidSiteLink] = useState(false);
  const [updatedSiteLink, setUpdatedSiteLink] = useState("");
  const [siteTitle, setSiteTitle] = useState("");
  const [siteTitleError, setSiteTitleError] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [action, setAction] = useState("");
  const [isSiteChanged, setIsSiteChanged] = useState(false);

  const userId = Cookies.get("userId");

  const handleSiteLinks = (e) => {
    const enteredSiteLink = e.target.value;
    setUpdatedSiteLink(enteredSiteLink);
    const urlCheck = /^(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    if (enteredSiteLink.trim() !== " ") {
      setIsValidSiteLink(urlCheck.test(enteredSiteLink));
      setSiteLinkError("Enter a valid domain name");
    } else {
      setIsValidSiteLink(urlCheck.test(enteredSiteLink));
      setSiteLinkError("");
    }
  };

  const handleSiteTitle = (e) => {
    const enteredSiteTitle = e.target.value;
    const titleOfTheSite = enteredSiteTitle.trim();
    setSiteTitle(enteredSiteTitle);
    if (titleOfTheSite.length > 20) {
      setSiteTitleError("It should contain less than 20 characters.");
    } else {
      setSiteTitleError("");
    }
  };

  const fetchExistingSites = () => {
    setUpdatedSiteLink(addedSiteLink);
    setSiteTitle(addedSiteTitle);
  };

  const updateSites = async () => {
    try {
      if (!updatedSiteLink.trim() && !siteTitle.trim()) {
        setSiteLinkError("Site Link is required");
        setSiteTitleError("Site Title is required");
        return { isSiteError: false };
      } else if (!updatedSiteLink.trim()) {
        setSiteLinkError("Site Link is required");
      } else if (!siteTitle.trim()) {
        setSiteTitleError("Site Title is required");
      } else {
        const response = await axios.post(
          `${API_DOMAIN}/site`,
          { userId: userId, url: updatedSiteLink, title: siteTitle },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const result = response.data;
        if (result.success) {
          fetchSites();
          return { isSiteError: true };
        } else {
          const isNotFound = result.code === 404;

          if (isNotFound) {
            setIsConsoleError(true);
            setConsoleError(result.error);
            return { success: false };
          } else {
            return { success: false };
          }
        }
      }
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const deleteSite = async () => {
    try {
      await axios.delete(`${API_DOMAIN}/site/delete`, {
        data: {
          userId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      siteState(false);
    } catch (error) {
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleUpdate = async (close) => {
    const result = await updateSites();
    if (result && result.isSiteError) {
      close();
      setAction("updated");
      setIsSiteChanged(true);
      setIsVisible(true);
    }
  };

  const handleDelete = async (close) => {
    await deleteSite();
    close();
    fetchSites();
    setAction("deleted");
    setIsSiteChanged(true);
    setIsVisible(true);
  };

  return (
    <>
      <Popup
        trigger={
          <span className="sites-options">
            <BsThreeDots className="sites-options-icon" onClick={fetchExistingSites} />
          </span>
        }
        modal
        nested
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="sites-options-popup">
            <div className="sites-options-header">
              <div className="sites-options-title">Quick Strip</div>
              <button className="sites-options-close" onClick={close}>
                <CloseButton style={{ height: "5px", width: "5px" }} />
              </button>
            </div>
            <div className="sites-options-body">
              <div className="add-sites-input">
                <div className="add-sites-input">
                  <label> Site Title </label>
                  <input
                    type="text"
                    placeholder="Site Title"
                    className="add-site-text"
                    value={siteTitle}
                    onChange={handleSiteTitle}
                  />
                  {siteTitleError && (
                    <div className="error-msg-site-link">{siteTitleError}</div>
                  )}
                </div>
                <label> Site URL </label>
                <input
                  type="text"
                  placeholder="Site URL"
                  className="add-site-text"
                  value={updatedSiteLink}
                  onChange={handleSiteLinks}
                />
                {isValidSiteLink ? (
                  ""
                ) : (
                  <div className="error-msg-site-link">{siteLinkError}</div>
                )}
              </div>
            </div>
            <div className="add-site-btn">
              <div className="site-remove" onClick={() => handleDelete(close)}>
                Delete
              </div>

              <div className="site-update" onClick={() => handleUpdate(close)}>
                Update
              </div>
            </div>
          </div>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
      />
      <ConfirmationMessage
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        action={action}
        isSiteChanged={isSiteChanged}
      />
    </>
  );
}

export default SitesOption;
