import React, { useState } from "react";
import Popup from "reactjs-popup";
import { RxCross2 } from "react-icons/rx";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TumblrShareButton,
  ViberShareButton,
  FacebookIcon,
  XIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TumblrIcon,
  ViberIcon,
} from "react-share";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import "./SocialShare.css";

function SocialShare({ closeShareModal, linkToShare, contentToShare }) {
  const [isCopiedText, setIsCopiedText] = useState(false);
  const currentPageUrl = linkToShare;
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(currentPageUrl).then(() => {
      setTimeout(() => {
        setIsCopiedText(false);
      }, 1000);
    });
  };

  return (
    <Popup
      trigger={
        <div className="spp-share-btn" onClick={() => closeShareModal(false)}>
          Share
        </div>
      }
      modal
      nested
      closeOnDocumentClick={false}
    >
      {(close) => (
        <div className="social-share-popup">
          <div className="social-popup-header">
            <div className="ss-box">
              <div className="ss-close">
                <button className="ss-close-btn" onClick={() => close()}>
                  <RxCross2 />
                </button>
              </div>
              <div className="ss-title">
                <h> Share Your Link </h>
              </div>
            </div>
          </div>
          <div className="social-popup-body">
            <div className="social-popup-body-canvas">
              <div className="share-icons">
                <div className="share-icon">
                  <FacebookShareButton
                    url={contentToShare}
                    hashtag="#tinlyprofile"
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                </div>
                <div className="share-icon">
                  <TwitterShareButton url={contentToShare}>
                    <XIcon size={32} round={true} />
                  </TwitterShareButton>
                </div>
                <div className="share-icon">
                  <TelegramShareButton url={contentToShare}>
                    <TelegramIcon size={32} round={true} />
                  </TelegramShareButton>
                </div>
                <div className="share-icon">
                  <WhatsappShareButton url={contentToShare}>
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </div>
                <div className="share-icon">
                  <EmailShareButton
                    url={contentToShare}
                    subject="Tinly Profile Link"
                  >
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                </div>
                <div className="share-icon">
                  <FacebookMessengerShareButton url={contentToShare}>
                    <FacebookMessengerIcon size={32} round={true} />
                  </FacebookMessengerShareButton>
                </div>
                <div className="share-icon">
                  <LinkedinShareButton url={contentToShare}>
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                </div>
                <div className="share-icon">
                  <TumblrShareButton url={contentToShare}>
                    <TumblrIcon size={32} round={true} />
                  </TumblrShareButton>
                </div>
                <div className="share-icon">
                  <ViberShareButton url={contentToShare}>
                    <ViberIcon size={32} round={true} />
                  </ViberShareButton>
                </div>
                <div className="share-icon">
                  <div
                    onClick={handleCopyToClipboard}
                    className="copy-link-button"
                  >
                    <MdOutlineContentCopy
                      size={30}
                      onClick={() => setIsCopiedText(true)}
                    />
                  </div>
                </div>
                {isCopiedText && (
                  <div className="success-copied">
                    <div className="success-icon">
                      <FaCheckCircle />
                    </div>
                    <div className="success-text">Copied to Clipboard</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default SocialShare;
